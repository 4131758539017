import {
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState} from 'react'
import {BpsCounterImbalance} from '../../../api/generated'
import {StyledIconButton} from '.././GroupNominationsTable'
import {transformToKwNumber} from '../../../utils/common'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {isBpsEditable} from '../../../utils/validateUserRolesAndBps'

const StyledTableHeadRowTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const StyledTableCell = styled(TableCell)(() => ({
  width: '70%',
}))

type CounterImbalanceTableProps = {
  setIsEditCounterImbalanceDialogOpen: (flag: boolean) => void
  bpsCounterImbalance: BpsCounterImbalance | undefined
  checkBoxEnableCI: boolean
}

export const CounterImbalanceTable: React.FC<CounterImbalanceTableProps> = ({
  setIsEditCounterImbalanceDialogOpen,
  bpsCounterImbalance,
  checkBoxEnableCI,
}) => {
  const [openTableRow, setOpenTableRow] = useState<number | undefined>(1)
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const handleOpenCounterImbalance = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseCounterImbalanceTable = () => {
    setOpenTableRow(-1)
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{gap: '12px', display: 'flex'}}
        style={{marginTop: '10px'}}
      >
        <TableContainer component={Paper}>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableHead>
              <StyledTableHeadRowTable>
                <StyledTableCell>Proti-odchýlkovanie</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      openTableRow == 1 ? handleCloseCounterImbalanceTable() : handleOpenCounterImbalance(1)
                    }
                  >
                    {openTableRow == 1 ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </StyledTableCell>
              </StyledTableHeadRowTable>
            </TableHead>

            <TableBody>
              <>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={openTableRow == 1} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table size="small" sx={{padding: '20px 0'}}>
                          <TableHead>
                            <StyledTableCell>Parametre</StyledTableCell>
                            <StyledTableCell>Hodnota</StyledTableCell>
                            <StyledTableCell style={{borderBottom: 'none'}}>
                              <StyledIconButton
                                sx={{marginLeft: '5px'}}
                                color="primary"
                                onClick={() => setIsEditCounterImbalanceDialogOpen(true)}
                                disabled={!isBpsEditable(userInfo, selectedBpsId)}
                              >
                                <Create fontSize="small" />
                              </StyledIconButton>
                            </StyledTableCell>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none', verticalAlign: 'middle'}}>
                                  Povoliť proti-odchýlkovanie
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none', display: 'flex'}}>
                                  <Checkbox style={{padding: 0}} checked={checkBoxEnableCI} disabled />
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Regulačný rozsah nahor od Nominácie
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  {bpsCounterImbalance?.range_ci_max_mw == undefined
                                    ? '-'
                                    : transformToKwNumber(bpsCounterImbalance.range_ci_max_mw)}{' '}
                                  kW
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  Regulačný rozsah nadol od Nominácie
                                </StyledTableCell>
                                <StyledTableCell style={{borderBottom: 'none'}}>
                                  {bpsCounterImbalance?.range_ci_min_mw == undefined
                                    ? '-'
                                    : transformToKwNumber(bpsCounterImbalance.range_ci_min_mw)}{' '}
                                  kW
                                </StyledTableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  )
}
