import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useBucketStore} from '../utils/BucketStoreContext'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {updatePriceParamsForBps} from '../api/bpsApi'
import {BpsPriceParams} from '../api/generated'

type EditBpsPriceParamsProps = {
  open: boolean
  onClose: () => void
  onUpdate: () => void
  bpsPriceParams: BpsPriceParams | undefined
}

const StyledStack = styled(Stack)(() => ({
  '& > *:first-child': {
    width: '72%',
    minWidth: '100px',
  },
  '& > *:last-child': {
    width: '28%',
    minWidth: '100px',
  },
}))

export const EditBpsPriceParamsDialog: React.FC<EditBpsPriceParamsProps> = ({
  open,
  onClose,
  onUpdate,
  bpsPriceParams,
}) => {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState<boolean>(
    bpsPriceParams?.supports_payoff === undefined ? false : bpsPriceParams.supports_payoff,
  )
  const [gasPrice, setGasPrice] = React.useState<number | undefined>(bpsPriceParams?.gas_price)
  const [urso, setUrso] = React.useState<number | undefined>(bpsPriceParams?.urso)
  const [fixContractMw, setFixContractMw] = React.useState<number | undefined>(bpsPriceParams?.fix_contract_mw)
  const [fixContractPrice, setFixContractPrice] = React.useState<number | undefined>(bpsPriceParams?.fix_contract_price)
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: userInfo} = useBucketStore('userInfo')

  const {run: runUpdatePriceParamsForBps} = useAsyncMethodWithErrorHandling(updatePriceParamsForBps)

  const handleConfirm = async () => {
    setIsConfirmDisabled(true)
    if (selectedBpsId) {
      await runUpdatePriceParamsForBps({
        updateBpsPriceParamsRequest: {
          bps_id: selectedBpsId,
          gas_price: gasPrice ? gasPrice : 0,
          supports_payoff: checkBoxChecked,
          urso: checkBoxChecked ? urso : undefined,
          fix_contract_mw: fixContractMw ? fixContractMw : 0,
          fix_contract_price: fixContractPrice ? fixContractPrice : 0,
        },
      })
    }
    setIsConfirmDisabled(false)
    onUpdate()
  }

  const validateIfPositiveNumber = (number: string) => {
    return Number(number) >= 0
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Upraviť cenové parametre</DialogTitle>
        <DialogContent>
          <StyledStack direction="row" alignItems="center" marginY={1}>
            <Typography>Cena paliva [EUR/MWh]</Typography>
            <TextField
              value={gasPrice}
              type="number"
              size={'small'}
              onChange={(e) => {
                validateIfPositiveNumber(e.target.value) ? setGasPrice(Number(e.target.value)) : 0
              }}
            />
          </StyledStack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" marginY={1}>
            <Typography>Podpora doplatkom</Typography>
            <Checkbox
              checked={checkBoxChecked}
              onChange={(_, checked) => {
                setCheckBoxChecked(checked)
              }}
            />
          </Stack>
          {checkBoxChecked && (
            <StyledStack direction="row" alignItems="center" marginY={1}>
              <Typography>URSO cenové rozhodnutie [EUR/MWh]</Typography>
              <TextField
                value={urso}
                type="number"
                size={'small'}
                onChange={(e) => {
                  validateIfPositiveNumber(e.target.value) ? setUrso(Number(e.target.value)) : 0
                }}
              />
            </StyledStack>
          )}
          <StyledStack direction="row" alignItems="center" marginY={1}>
            <Typography>Fixný kontrakt [MW]</Typography>
            <TextField
              disabled={!userInfo?.roles.includes('GROUP_MNG') && !userInfo?.roles.includes('GROUP_OPERATOR')}
              value={fixContractMw}
              type="number"
              size={'small'}
              onChange={(e) => {
                validateIfPositiveNumber(e.target.value) &&
                (userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR'))
                  ? setFixContractMw(Number(e.target.value))
                  : 0
              }}
            />
          </StyledStack>
          <StyledStack direction="row" alignItems="center" marginY={1}>
            <Typography>Cena fixného konkraktu [EUR/MWh]</Typography>
            <TextField
              disabled={!userInfo?.roles.includes('GROUP_MNG') && !userInfo?.roles.includes('GROUP_OPERATOR')}
              value={fixContractPrice}
              type="number"
              size={'small'}
              onChange={(e) => {
                validateIfPositiveNumber(e.target.value) &&
                (userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR'))
                  ? setFixContractPrice(Number(e.target.value))
                  : 0
              }}
            />
          </StyledStack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={handleConfirm} variant="contained" disabled={isConfirmDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
