import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import React, {useState} from 'react'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'
import {getReportExport} from '../api/reportApi'
import {DATE_AND_TIME_FORMAT} from '../utils/constants'

type ReportExportDialogProps = {
  open: boolean
  onClose: () => void
}

export const ReportExportDialog: React.FC<ReportExportDialogProps> = ({open, onClose}) => {
  const [dateSource, setDateSource] = useState<string | null>(null)
  const [dateTarget, setDateTarget] = useState<string | null>(null)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true)

  const {data: userInfo} = useBucketStore('userInfo')

  const {run: runGetReportExport} = useAsyncMethodWithErrorHandling(getReportExport)

  const fetchReportExport = React.useCallback(
    async (date_from: string, date_to: string, user_id: number) => {
      await runGetReportExport({dateFrom: date_from, dateTo: date_to, userId: user_id})
    },
    [runGetReportExport],
  )

  const handleSendReportExport = () => {
    setIsConfirmDisabled(true)
    if (!dateSource || !dateTarget) {
      return
    }

    const dateSourceFormatted = dateSource.substring(0, 10)
    const dateTargetFormatted = dateTarget.substring(0, 10)
    const userId = userInfo?.userId

    if (userId && userId > 0) {
      fetchReportExport(dateSourceFormatted, dateTargetFormatted, userId)
    }

    setIsConfirmDisabled(false)
    onClose()
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>OKTE dáta a nominácie na môj email</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Od dňa:</Typography>
            <MobileDatePicker<string, Dayjs>
              value={dateSource}
              onChange={(newValue) => {
                setDateSource(newValue?.format(DATE_AND_TIME_FORMAT) ?? null)
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
              showToolbar={false}
              onAccept={() => setIsConfirmDisabled(!dateSource || !dateTarget)}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end" marginY={2}>
            <Typography>Do dňa:</Typography>
            <MobileDatePicker<string, Dayjs>
              value={dateTarget}
              onChange={(newValue) => {
                setDateTarget(newValue?.format(DATE_AND_TIME_FORMAT) ?? null)
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              componentsProps={{actionBar: {actions: ['cancel', 'accept']}}}
              showToolbar={false}
              onAccept={() => setIsConfirmDisabled(!dateSource || !dateTarget)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={() => handleSendReportExport()} variant="contained" disabled={isConfirmDisabled}>
            Odoslať
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
