import {
  ReportControllerApi,
  ReportControllerApiGetBpsGroupReportRequest,
  ReportControllerApiGetReportCsvExportRequest,
  ReportControllerApiGetNominationSnapshotReportCsvRequest,
} from './generated'
import {getConfiguration} from './configuration'

export const getBpsGroupReport = async (params: ReportControllerApiGetBpsGroupReportRequest) => {
  const configuration = getConfiguration()
  const api = new ReportControllerApi(configuration)

  return (await api.getBpsGroupReport(params)).data
}

export const getReportExport = async (params: ReportControllerApiGetReportCsvExportRequest) => {
  const configuration = getConfiguration()
  const api = new ReportControllerApi(configuration)

  return (await api.getReportCsvExport(params)).data
}

export const getNominationSnapshotCsvReport = async (
  params: ReportControllerApiGetNominationSnapshotReportCsvRequest,
) => {
  const configuration = getConfiguration()
  const api = new ReportControllerApi(configuration)

  return (await api.getNominationSnapshotReportCsv(params)).data
}
